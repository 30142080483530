import { url_server, sgt_server } from './config.js'

export function setToken(token){
    localStorage.setItem('token', token)
}

export function getToken(){
    let token = localStorage.getItem('token') || undefined
    return token
}

export function delToken(){
    localStorage.removeItem('token')
}

export function numberWithCommas(x) {
    if(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
        return '0'
    }
    
}

export async function getData(token, path, data, method, signal){
    //console.log(data)

    if (!method) {
        method = 'GET'
    }

    const request = {
        crossDomain : true,
        method  : method,
        headers : new Headers({
            'Content-Type' : 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'Token' : token,
        }),
        //mode    : 'cors',
        //cache   : 'default',
    }


    if (method == "POST") {
        request ['body'] = JSON.stringify(data);
        if(signal) request.signal = signal
    }

    var data = undefined

    try {
        let url = url_server + path

        const response = await fetch(url, request)

        switch(response.status){
            case 200 : {
                data = await response.json()

                break;
            }

            case 401 : {
                delToken()
                window.location = '/'
                break;
            }
        }

    } catch (err) {
        console.log(err)
    }

    return data
}


// INTER CONNECTION WITH SGT
export async function getDataSGT(path, data, method){
    //console.log(data)

    if (!method) {
        method = 'GET'
    }

    const request = {
        crossDomain : true,
        method  : method,
        headers : new Headers({
            'Content-Type' : 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
        })
    }


    if (method == "POST") {
        request ['body'] = JSON.stringify(data)
    }

    var data = undefined

    try {
        let url = sgt_server + path

        const response = await fetch(url, request)

        switch(response.status){
            case 200 : {
                data = await response.json()

                break;
            }

            case 401 : {
             console.log("SGT DATA ERROR")
            }
        }

    } catch (err) {
        console.log(err)
    }

    return data
}



// INTER CONNECTION WITH SGT
export async function getDataOUT(path, data, method){
    //console.log(data)

    if (!method) {
        method = 'GET'
    }

    const request = {
        crossDomain : true,
        method  : method,
        headers : new Headers({
            'Content-Type' : 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
        })
    }


    if (method == "POST") {
        request ['body'] = JSON.stringify(data)
    }

    var data = undefined

    try {
        let url = url_server + path

        const response = await fetch(url, request)

        switch(response.status){
            case 200 : {
                data = await response.json()

                break;
            }

            case 401 : {
             console.log("SGT DATA ERROR")
            }
        }

    } catch (err) {
        console.log(err)
    }

    return data
}

export function getName(data, _id, key){
    let name

    for (var i = 0; i < data.length; i++) {
        if(data[i]._id == _id){
            name = data[i][key]
        }
    }

    return name
}


