import * as React from "react";
import { useEffect, useState } from "react";
import { render } from "react-dom";
import { Grid, Backdrop, CircularProgress, Button, TextField, Modal, Checkbox, FormControlLabel } from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import L from 'leaflet'

export default function Cobertura(props) {
  
  const [fte, setFte]  = useState(true);
  const [Roaming_Movistar_3G, setRoaming_Movistar_3G] = useState(false);
  const [Roaming_Movistar_LTE, setRoaming_Movistar_LTE] = useState(false);
  const [Telcel_2G_Roaming, setTelcel_2G_Roaming] = useState(false);
  const [Telcel_3G_Roaming, setTelcel_3G_Roaming] = useState(false);
  const [Telcel_LTE_Roaming, setTelcel_LTE_Roaming] = useState(false);
  const [str,setStr] = useState('Cobertura_Actual,Roaming_Movistar_3G,Roaming_Movistar_LTE,Telcel_2G_Roaming,Telcel_3G_Roaming')
  var map = null;
  var wmsLayer;

  function armarStr(){
      
      console.log(fte,Roaming_Movistar_3G,Roaming_Movistar_LTE,Telcel_2G_Roaming,Telcel_3G_Roaming)

      var str2 = ""
      if(fte){ str2+= 'Cobertura_Actual,'}
      if(Roaming_Movistar_3G){ str2+= 'Roaming_Movistar_3G,'}
      if(Roaming_Movistar_LTE){ str2+= 'Roaming_Movistar_LTE,'}
      if(Telcel_2G_Roaming){ str2+= 'Telcel_2G_Roaming,'}
      if(Telcel_3G_Roaming){ str2+= 'Telcel_3G_Roaming,'}
      if(Telcel_LTE_Roaming){ str2+= 'Telcel_Lte_Roaming,'}


      console.log(str2, "fial sr") 
      str2 = str2.replace(/,\s*$/, "");
     
      try{
        setStr(str2)
       var  mapWrp = document.querySelector('.map-wrapper');
        map = document.querySelector('#map');

//Create a new map element
var newMap = document.createElement('div');
newMap.setAttribute('id', 'map');
newMap.setAttribute('style', 'height:500px;');

// Remove older/previous map element
mapWrp.removeChild(map);
mapWrp.append(newMap);

        map = L.map('map').setView([19.2878600, -99.6532400],6);
    
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
           // attribution: '<a href="www.altanredes.com">ALTAN</a> Altan-Maps',
            maxZoom: 18
        }).addTo(map);
    
        L.control.scale().addTo(map);
        //L.marker([19.2878600, -99.6532400], {draggable: true}).addTo(map);
        wmsLayer = L.tileLayer.wms('https://geomap.altanredes.com/geoserver/web_altanredes_geoaltan/ows?SERVICE=WMS?&authkey=967baf96', {
            layers: str2,
            format: 'image/png',
            transparent: true,
            tiled: true
            //,	crs: 'EPSG:3857'
            
          }).addTo(map)
       if(!wmsLayer){

     
       }
      } catch (e){ console.log(e)}
      

    
 
  }


  useEffect(() => {

     
     map = L.map('map').setView([19.2878600, -99.6532400],6);
    
    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
       // attribution: '<a href="www.altanredes.com">ALTAN</a> Altan-Maps',
        maxZoom: 18
    }).addTo(map);

    L.control.scale().addTo(map);
    //L.marker([19.2878600, -99.6532400], {draggable: true}).addTo(map);
    wmsLayer = L.tileLayer.wms('https://geomap.altanredes.com/geoserver/web_altanredes_geoaltan/ows?SERVICE=WMS?&authkey=967baf96', {
        layers: str,
        format: 'image/png',
        transparent: true,
        tiled: true
        //,	crs: 'EPSG:3857'
        
      }).addTo(map)

      if ("geolocation" in navigator) {
        console.log("Available");
      } else {
        console.log("Not Available");
      }
      
      
  },[])


  React.useEffect(() => { armarStr();}, [fte])
  React.useEffect(() => { armarStr();}, [Roaming_Movistar_3G])
  React.useEffect(() => { armarStr();}, [Roaming_Movistar_LTE])
  React.useEffect(() => { armarStr();}, [Telcel_2G_Roaming])
  React.useEffect(() => { armarStr();}, [Telcel_3G_Roaming])


  const askLocation = ()=>{
    navigator.geolocation.getCurrentPosition(function(position) {
      
     
       var  mapWrp = document.querySelector('.map-wrapper');
        map = document.querySelector('#map');
try {
//Create a new map element
var newMap = document.createElement('div');
newMap.setAttribute('id', 'map');
newMap.setAttribute('style', 'height:500px;');

// Remove older/previous map element
mapWrp.removeChild(map);
mapWrp.append(newMap);

        map = L.map('map').setView([position.coords.latitude,position.coords.longitude],11);
    
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
           // attribution: '<a href="www.altanredes.com">ALTAN</a> Altan-Maps',
            maxZoom: 18
        }).addTo(map);
    
        L.control.scale().addTo(map);
        //L.marker([19.2878600, -99.6532400], {draggable: true}).addTo(map);
        wmsLayer = L.tileLayer.wms('https://geomap.altanredes.com/geoserver/web_altanredes_geoaltan/ows?SERVICE=WMS?&authkey=967baf96', {
            layers: str,
            format: 'image/png',
            transparent: true,
            tiled: true
            //,	crs: 'EPSG:3857'
            
          }).addTo(map)

       
      } catch (e){ console.log(e)}
      
     
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
  }


  return (

   
    <div>
     <h1>Cobertura nacional celular FTE </h1> 

     <div style={{
        marginTop : 20,
        textAlign : 'center',
        padding : 5,
        backgroundColor : '#eb6324',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 20,
        float: 'left',
        margin : '10px',
        fontWeight : 'bold',
        cursor : 'pointer',
        verticalAlign:'middle',
        textAlign : 'right'
    }} onClick={() => {askLocation()}} ><LocationOnIcon /> Buscar cobertura <br />en mi ubicacion</div>

     <div className={"map-wrapper"} style={{height:'500px'}}>

       
        <div  style={{height:'500px'}} id="map"></div>
      </div>
   


     <FormControlLabel
          
          control={<Checkbox color="primary" checked={fte}  onChange={(e) => { e.preventDefault();setFte(e.target.checked)} }   />}
          label="Cobertura FTE"
          labelPlacement="end"
      />

    <FormControlLabel
             
              control={<Checkbox color="primary" checked={Roaming_Movistar_3G}  onChange={(e) => { setRoaming_Movistar_3G(e.target.checked)}} />}
              label="Movistar 3G"
              labelPlacement="end"
          />

    <FormControlLabel
              
              control={<Checkbox color="primary" checked={Roaming_Movistar_LTE}  onChange={(e) => { setRoaming_Movistar_LTE(e.target.checked)} } />}
              label="Movistar LTE"
              labelPlacement="end"
          />

    <FormControlLabel
              
              control={<Checkbox color="primary" checked={Telcel_2G_Roaming}  onChange={(e) => { setTelcel_2G_Roaming(e.target.checked)}} />}
              label="Telcel  2G"
              labelPlacement="end"
          />

    <FormControlLabel
             
              control={<Checkbox color="primary" checked={Telcel_3G_Roaming}  onChange={(e) => { setTelcel_3G_Roaming(e.target.checked)}} />}
              label="Telcel  3G"
              labelPlacement="end"
          />


<FormControlLabel
             
             control={<Checkbox color="primary" checked={Telcel_LTE_Roaming}  onChange={(e) => { setTelcel_LTE_Roaming(e.target.checked)}} />}
             label="Telcel  LTE"
             labelPlacement="end"
         />    


       
    </div>
  );
}


