import React, {useContext, List, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { numberWithCommas } from '../../functions';
import  { UserContext }  from '../../contexts/UserContext';
import {  readOffers, d_openInfo,d_createUser,d_cardList,d_payment,d_removeCard, readUserInfo, updateUserInfo, d_oxxorefr} from '../../components/dataController';
//import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, Tabs, Tab, Modal, Checkbox } from "@material-ui/core";
//import RecargaItem from "./RecargaItem";
import AlertMessage from '../../components/fields/AlertMessage';
import ButtonField from '../../components/fields/ButtonField';
import TextField from '../../components/fields/TextField';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Fade from '@material-ui/core/Fade';
//import Section from '../components/Section';
//import AddCard from '../components/AddCard'
import Select from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';


var user = null;


const openId = null;

class Recargas extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : false,
        dataUser : {} ,
        offers : [],
        offersF : [],
        pago_open : false,
        cardModal : false,
        deleteModal : false,
        oxxoModal : false,
        openId : null,
        cards : [],
        selCard : null,
        cardWaiting : false,
        numbers : [],
        selectedNum : null,
        nombre : '',
        apellidos : '',
        checkout : false,
        subscriptionMode : true
    } 

    

    static contextType = UserContext;
    
    componentDidMount(){

       // DEV 
       //window.OpenPay.setId('mnypp7qydn7msvpm5uii');
       // window.OpenPay.setApiKey('pk_ed5a406538014dd29102791791d39465');
        //window.OpenPay.setSandboxMode(true);

      //Production
      //  window.OpenPay.setId('mlcotkjrngo7l6keycg6');
      //  window.OpenPay.setApiKey('pk_da94c22afc9948daa046114bb08c8bd6');
      //  window.OpenPay.setSandboxMode(false);

        
        /*this.context.loginInfo(()=>{

            //console.log(this.context.user,"context",this.context.user.numbers)

            var options = [];

            for (let index = 0; index < this.context.user.numbers.length; index++) {
                options.push({value:this.context.user.numbers[index].msisdn,label:this.context.user.numbers[index].msisdn + "   --    " + this.context.user.numbers[index].alias})
                
            }

            //console.log(this.context.user.numbers[0].msisdn,"numero seleccionado")
            this.setState({numbersArr:options,waiting:false,selectedNum:
                {value: this.context.user.numbers[0].msisdn,label:this.context.user.numbers[0].msisdn +  "   --    " + this.context.user.numbers[0].alias} })
            
            this.getNumberInfo(this.context.user.numbers[0].msisdn).then( item => {
                console.log(item,'item fg')
                if(item){
                    this.getOfs(item.responseSubscriber.primaryOffering.offeringId)
                }
                
            })
        }); */

        //this.context.tokenize()
        //Sandbox

        window.location = 'https://recarga.fte.mx'

    }

    searchNumber = () =>{
        console.log(this.state.numberto,'number')
        this.setState({waitingOffers:true,offers:[],offersF:[],selectedNum:{value: this.state.numberto, label:'numerop c'}})    
        this.getNumberInfo(this.state.numberto).then( item => {
            if(item){
                
                this.getOfs(item.responseSubscriber.primaryOffering.offeringId);
            }
            
        })
        
    }

     async getNumberInfo (msisdn){
        let dataAltan  = await readUserInfo(msisdn);
        return dataAltan;
    }

    getOfs(offer){
       // console.log(offer,'offer to go',this.context.user.openId)
        let offerid = offer //this.context.user.ALTAN.responseSubscriber.primaryOffering.offeringId;
            this.getOffers(offerid);

           /* if(this.context.user.openId==undefined){
                //console.log({name:this.context.user.nombre + " " + this.context.user.apellidos ,email:this.context.user.username, userId: this.context.user._id}, 'creando usuario')
                if(this.context.user.nombre==undefined || this.context.user.nombre==''){
                    this.setState({nameModal:true});
                }else{
                    this.createUser({name:this.context.user.nombre + " " + this.context.user.apellidos ,email:this.context.user.username, userId: this.context.user._id});
                }
                
            }else{
                this.setState({openId:this.context.user.openId});
                this.cardList();
            }*/
    }

 
    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    getOffers(offerid){
        console.log(offerid,"offerid")
        readOffers(offerid).then(data=>{
            this.setState({offers:data,waitingOffers:false,offersF:data})
            console.log(data,"data")
        })
    }

    openpay = (item) =>{
       // console.log(item._id,"item proc",this.state.offers)
        let  items  = this.state.offers;
        let selItem = null

        let offerFinal = [];
       
            for (let index = 0; index < items.length; index++) {
                if(items[index]._id == item._id){
                    //items[index].selected = true;
                    selItem = items[index];
                    selItem.selected = true
                    offerFinal.push(selItem)
                } else{
                    items[index].selected = false;
                }
            }


          if(item.id==999999){
              offerFinal = this.state.offers;
          }  
      
        
        //console.log(items,"ITEMS YA")
        this.setState({pago_open:true,offersF : offerFinal,selItem:selItem})
    }



    clearItems = ()=>{
        this.setState({selItem:null,selCard:null})
        var item = {id:999999}
        this.openpay(item);
    }

    updateNames = (item) => {
        console.log(this.state.nombre,this.state.apellidos,"asdasd")
        if(this.state.nombre!='' && this.state.apellidos!=''){
            //this.context.user.nombre
           

            this.updateUser(this.state.nombre,this.state.apellidos)
            this.createUser({name:this.state.nombre + " " + this.state.apellidos ,email:this.context.user.username, userId: this.context.user._id});

            this.handleNameModal()
        }else{
            alert('Complete los datos por favor.')
        }
    }

    async updateUser(nombre,apellidos){
        let userInfo = {
            nombre: this.state.nombre,
            apellidos : this.state.apellidos
        }
        await updateUserInfo(userInfo).then( res =>{
            console.log('regreso update de usuario')
            this.context.user.nombre = nombre;
            this.context.user.apellidos = apellidos;
            
        })
    }
    
    cardSelect = (item) =>{
        // console.log(item._id,"item proc",this.state.offers)
         let  items  = this.state.cards;
         let selItem = null
         
         for (let index = 0; index < items.length; index++) {
             if(items[index].id == item.id){
                 items[index].selected = true;
                 selItem = items[index]
                 
             } else{
                 items[index].selected = false;
             }
         }
         //console.log(items,"ITEMS YA")
         this.setState({cards : items,selCard:selItem})
     }

   async openInfo(data){
     await d_openInfo(data).then( res =>{
        console.log(res)
     })
   }

   async createUser(data){
     await d_createUser(data).then(res =>{
         console.log(res,'open id nuevo')
        this.setState({openId:res.data.openId});
        
     })
   }


   cardList = async() =>{
    let data = {clientId:this.context.user.openId}
    await d_cardList(data).then(res =>{
        if(res==null) { res = []}
        this.setState({cards:res,cardModal:false})
    })
   }

    addOXXO = async() =>{
        console.log(this.state.selItem, "SELECTED ITEM");

    let precio = this.state.selItem.PRECIOPUBLICO


        
    var data = {
        unit_price : precio,
        name :  "Usuario FTE",
        username :  "act@fte.net.mx",
        phone : this.state.selectedNum.value,
        sku : this.state.selItem.OFFERID
    }

    console.log(data,"oferta a mandar sku")

    this.setState({oxxoModal:true});

     await d_oxxorefr(data).then(res =>{
        var referencia =  res.charges.data[0].payment_method.reference;
        var barcode = res.charges.data[0].payment_method.barcode_url;
         this.setState({price:precio,referencia:referencia,barcode:barcode})
        console.log(res,'referencia oxxo')
    })
}
  
    handleOxxoModal = () =>{
    this.setState({oxxoModal:false});
   }

   handleCardModal = () =>{
    this.setState({cardModal:false});
   }

   handleDeleteModal = () =>{
    this.setState({deleteModal:false});
   }

   handleCompleteModal = () =>{
    this.setState({completeModal:false});
   }

   handleNameModal = () =>{
    this.setState({nameModal:false});
   }

 
   addCard = () =>{
       this.setState({cardModal:true})
   }

   

   completeF = () =>{
       this.setState({completeModal:false,selCard:null,selected:false,selItem:null});
       window.location = '/dash'
       
   }

   removeCardModal = (itemId)=>{
    this.setState({deleteModal:true,itemToDel:itemId})
   }
   removeCard = () =>{
     d_removeCard(this.state.itemToDel).then(()=>{
         this.cardList();
         this.setState({selCard:null,deleteModal:false})
     }) 
   }

   createAlertDiv(msg){
    alert(msg)
   }

    payment = async () =>{
        this.setState({cardWaiting:true});

        let data = {
            clientId : this.state.openId,
            source_id : this.state.selCard.id,
            method : 'card',
            amount : this.state.selItem.PRECIOPUBLICO,
            description : this.context.user.numbers[0].msisdn + " - " +  this.state.selItem.NOMBRECOMERCIAL,
            device_session_id :window.deviceSessionId,
            msisdn : this.context.user.numbers[0].msisdn,
            offerings : this.state.selItem.OFFERID
        }
        await d_payment(data).then(res =>{
            console.log(res,"RES",res.error_code)
            //alert(JSON.stringify(res));
            if(res.error_code){
                this.paymentResponse(res);
                this.setState({cardWaiting:false});
            }else{
                this.setState({cardWaiting:false,completeModal:true,orderId : res.order.id});
            }
            

            //this.setState({cards:res})
        })
    }

    setNombre = (event) => {   this.setState({nombre : String(event.target.value).trim()})}
    setApellidos = (event) => { this.setState({apellidos : String(event.target.value)})}

   paymentResponse = (res) =>{
    var http_code = res.http_code;
    var error_code = res.error_code;

    if(http_code != undefined){
        switch(error_code){
            case 3001:
                var msg = this.createAlertDiv("La tarjeta fue rechazada.");
                break;
            case 3002:
                var msg = this.createAlertDiv("La tarjeta ha expirado.");
                break;     
            case 3003:
                var msg = this.createAlertDiv("La tarjeta no tiene fondos suficientes.");
                break;    
            case 3004:
                var msg = this.createAlertDiv("La tarjeta ha sido identificada como una tarjeta robada.");
                break;
            case 3005:
                var msg = this.createAlertDiv("La tarjeta ha sido rechazada por el sistema antifraudes.");
                break;
            case 1000:
                var msg = this.createAlertDiv("Error en el gateway de pago intente mas tarde.");
                break;
            case 1001: 
                var msg = this.createAlertDiv("Verifique los datos de la tarjeta.");
                break;
            case 1003:
                var msg = this.createAlertDiv("Promocion invalida para el tipo de tarjeta.");
                break;
            case 2005:
                var msg = this.createAlertDiv("La fecha de expiración ya no es válida");
                break;
            case 2004:
                var msg = this.createAlertDiv("El dígito de verificación es inválido");
                break;
            default:
                var msg = this.createAlertDiv("Error en el sistema, contacte con el administrador.");
                break;
        }
    }else{


        var params = {};
         /*   $.each($('#payment-form').serializeArray(), function(_, kv) {
              params[kv.name] = kv.value;
            });
*/

       // var datos = ctrl_factura.mainR.get('datos');
       // var info = ctrl_factura.mainR.get('info');
      //  var tarjeta = ctrl_factura.mainR.get('tarjeta');

   /*     params.amount = parseFloat(ctrl_factura.factura.total).toFixed(2);
        params.description = "Pago Fobos caja "  // + info.resId
        params.customer = {}
        params.customer.name = tarjeta.name.split(" ")[0]
        params.customer.last_name = tarjeta.name.split(" ")[1] + tarjeta.name.split(" ")[2]
        params.customer.phone_number = ctrl_factura.userData.telefono//datos.telefonos
        params.customer.email = ctrl_factura.userData.email//datos.emailContacto;
        params.cid = ctrl_factura.userData.openId;
        params.source = res.card.id;
        params.device_session_id = deviceSessionId
    */   

        d_payment({},function(resus){
           
            
           
            // var data = {uuid: ctrl_factura.factura.uuid,'status':"Pagado"};
            //dbC.query('/clientes/updatefactura',"POST",data,ctrl_factura.updateRet);


        })


    }
   }


    render() {
        const { classes, history, session } = this.props;
        
        user = this.context.user;
        const data = this.state.data

      

        return (
            <div>
                <Backdrop  style={{zIndex:1000}} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 

              {/*  <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="card"
                    className={theme.modal}
                    open={this.state.cardModal}
                    onClose={this.handleCardModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.cardModal}>
                    <div className={theme.paper}>
                       <AddCard clientId={this.state.openId} refreshC={this.cardList} />
                    </div>
                    </Fade>
                </Modal>
                */ }
               

                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="deletecard"
                    className={theme.modal}
                    open={this.state.deleteModal}
                    onClose={this.handleDeleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.deleteModal}>
                    <div className={theme.paper}>
                    <div style={{backgroundColor:'#fff',padding:20}}>
                       ¿Desea remover esta tarjeta de sus métodos de pago? <br/>
                       <ButtonField style={{marginRight:'10px', float:'right'}} onClick={this.handleDeleteModal}  label='Cancelar' /> &nbsp;
                       <ButtonField style={{marginLeft:'10px', float:'right'}} onClick={this.removeCard}  label='Remover' />
                       </div>
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="deletecard"
                    className={theme.modal}
                    open={this.state.completeModal}
                    onClose={this.handleCompleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.completeModal}>
                    <div className={theme.paper}>
                        <div style={{backgroundColor:'#fff',padding:20}}>
                       ¡Su Recarga ha sido completada exitosamente!
                       <br/>
                        <h5>ID de orden {this.state.orderId}</h5>
                       <ButtonField onClick={this.completeF}  label='Aceptar' />
                       </div>
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="addname"
                    className={theme.modal}
                    open={this.state.nameModal}
                    onClose={this.handleNameModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.nameModal}>
                    <div className={theme.paper}>
                        <div style={{backgroundColor:'#fff',padding:20}}>
                       Por favor agregue su nombre y apellidos para completar su registro
                       <TextField     value={this.state.nombre} label='Nombre'   onChange={this.setNombre}   />
                       <TextField     value={this.state.apellidos} label='Apellidos'   onChange={this.setApellidos}   />
                       <ButtonField onClick={() => {this.updateNames()}}  label='Actualizar' />
                       </div>
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="oxxmodal"
                    className={theme.modal}
                    open={this.state.oxxoModal}
                    onClose={this.handleOxxoModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                    >
                    <Fade in={this.state.oxxoModal}>
                    <div className={theme.paper}>
                        <div style={{backgroundColor:'#fff',padding:10}}>
                     
                        
                       <div className={classes.opps}>
                            <div className={classes.oppsHeader}>
                            <div className={classes.oppsReminder}>Ficha digital. No es necesario imprimir.</div>
                            <div className={classes.oppsInfo}>
                                <div style={{widht:'100%',float:'left'}}>
                                <div className={classes.oppsBrand}><img className={classes.oppsBrandImg} src="oxxopay_brand.png" alt="OXXOPay" />
                                
                                </div>
                                <div className={classes.oppsAmount}>

                                

                                <p >Monto a pagar</p>
                                <img style={{marginTop:'0px'}} src={this.state.barcode} />
                                <h2 className={classes.oppsAmounth2}>$ {this.state.price}.00 <sup className={classes.oppsAmountsup}>MXN</sup></h2>
                                <p className={classes.oppsAmountp}>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                                </div>
                                </div>
                            </div>
                            <div className={classes.oppsReference}>
                                <h3 className={classes.oppsReferenceh3}>Referencia</h3>
                                <h1 className={classes.oppsReferenceh1}>{this.state.referencia}</h1>
                            </div>
                            </div>
                            <div className={classes.oppsInstructions}>
                            <h3>Instrucciones</h3>
                            <ol>
                                <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>
                                <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                                <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                                <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                            </ol>
                            
                            </div>
                        </div>
                      
                       </div>
                    </div>
                    </Fade>
                </Modal>

                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}


                {this.state.waiting ? "": 
                    <div>
                    <h1>Recargas</h1>  

                    <h5>Ingrese el número FTE </h5>
                    <div style={{float:'left'}}>
                    <input style={{fontSize:'18px',fontWeight:'bold',width:'150px',padding:'5px',marginRigth:'10px'}} value={this.state.numberto} onChange={(e)=>{ this.setState({numberto:e.target.value})}}  type='text'  />
                    </div>
                    <div style={{marginLeft:'10px',float:'left','marginTop': '-9px'}}>
                    <ButtonField style={{marginTop:'0px !important'}} onClick={()=>{this.searchNumber()}}  label='buscar recargas' />
                    </div>
                    

                    {this.state.waitingOffers ? (
                        <div style={{margin:'20px',marginLeft:'40px'}}>
                        <CircularProgress color="inherit" size={12} style={{float:'left', margin:3}} />
                        <div style={{float:'left'}}>Buscando ofertas...</div> 
                        </div>
                    ):null}

                  
            
                    <Grid container style={{marginTop:'20px'}} className={styles.root} spacing={1}>

                    {this.state.selItem ? (
                       <div style={{width:200, alignItems:'center',justifyContent:'center', margin:'30px auto'}}>
                       <ButtonField onClick={this.clearItems}  label='Ver todas las recargas' />
                       </div>
                       ) : null}


                    <Grid container 
                        direction="row"
                        justify="space-evenly"
                        alignItems="top"
                        spacing = {1}
                        >
                        {this.state.offersF.map( item => (

                            
                        <Grid item   key={item._id} onClick = { () =>{ this.openpay(item)}}  style={{ justify:'center'}}>
                        {(item.selected || this.state.selItem==null) ? (
                        
                        
                        <div className={classes.cuadroC}>
                        
                            <div className={[classes.cuadroT,item.selected   ?  classes.cuadroSel : null].join(' ')} >
                            
                            <div className={classes.cuadroPrecio}>${item.PRECIOPUBLICO} MXN</div>
                            
                            <div className={classes.cuadroSub}> {item.DIAS>0 ? (item.DIAS  + " días / ") : null} IVA incluido</div>
                            
                            
                            <div style={{minHeight:140}}>
                            <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:16,  marginRight:10,marginLeft:10}}/> <b>{ parseInt(item.MB) + parseInt(item.MBINT) + parseInt(item.MBTHR)}</b> MB</div>
                            <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:16,  marginRight:10,marginLeft:10}} /> <b>{parseInt(item.MINFUP) + parseInt(item.MINVOZ)}</b> minutos de voz</div>
                            <div style={{fontSize: 15}}><CheckCircleIcon style={{fontSize:16,  marginRight:10,marginLeft:10}} /> <b>{parseInt(item.SMS) + parseInt(item.SMSFUP)}</b> SMS</div>
                            <div style={{fontSize:12, marginLeft:35}}>SMS México, Estados unidos y Canadá</div>
                            
                        {item.TETERING=="SI" ? (
                            <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14, color:'green', marginRight:10,marginLeft:10, marginTop:20}} />Comparte Internet</div>
                        ) :null}

                        </div>


                            { this.state.selItem==null ? (
                                <div className={classes.cuadroBt} style={{cursor:'pointer'}}>SELECCIONAR <ArrowRightAltIcon style={{ verticalAlign:'middle'}} /></div>
                            ) : null}
                            
                        </div>
                    
                       
                        </div>
                           
                    
                    ) : null} 
                       </Grid>
 
                        ))}
                        
        

                {this.state.selItem ? (  
                <div>  
                   <Grid container className={styles.root} spacing={2}>
                    <Grid container 
                        direction="row"
                        justify="flex-start"
                        >
                           {this.state.cards.map( item => (
                                  <Grid item   className={classes.cardT}
                                    key={item._id} onClick = { () =>{ this.cardSelect(item)}}  style={{ justify:'center'}}>
                                  <div className={classes.cuadroC} style={{cursor:'pointer'}}>
                                  <div className={[classes.cuadroTarjeta,item.selected ?  classes.cuadroSel :null].join(' ')} >
                                      <div className={classes.removeCard} onClick={()=>{this.removeCardModal(item.id)}}>x</div>
                                          <div className={classes.cuadroTitle}>{item.brand=="visa" ? <img style={{height: 30,paddingTop: 8}} src="/visa.png"/> : <img style={{height: 30,paddingTop: 8}} src="/masterCard.png"/>} </div>
                                           <div className={classes.cuadroNombre}>{item.bank_name} <br/> {item.holder_name}</div>  
                                          <div className={classes.cuadroNumeroTarjeta}>{item.card_number}</div>

                                      </div>
                                  </div>
                              </Grid> 
                            ))}

                        </Grid>

                    </Grid>

                  {/*
                    {this.state.cards.length<3 ? (
                            <div style={{marginTop:15, float:'right'}}>
                            <ButtonField onClick={this.addCard}  label='Agregar Tarjeta Débito / Crédito' />
                            </div>
                        )  :null}

                   */}
                    
                

                    
                    
                       {this.state.selCard ? (
                            <div >
                          <div onClick={this.payment} className={classes.pagarBt} style={{width:'100%', float:'left'}}>
                              $ {numberWithCommas(this.state.selItem.PRECIOPUBLICO)} <br />
                              Realizar Pago 
                        </div>
                        <div>

                      ´{/*  <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.subscriptionMode}
                            onChange={()=>{console.log('cmabio checked')}}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Registrar como pago recurrente para renovación automática"
                        />
                        <p>Al finalizar el plan se renovará automaticamente con cargo a la tarjeta selccionada</p>

                        */}

                        </div>

                          </div>
                    ) :null}

                    <div style={{width:'100%',height:'20px', marginTop:10, marginBottom:100, backgroundColor:'0x3333333', borderTop : '1px solid #ccc', float:'left'}}>
                    <img className={classes.oppsBrandImg} src="oxxopay_brand.png" alt="OXXOPay" /><br/>
                       <ButtonField onClick={this.addOXXO}  label='Pago con Referencia OXXO' />
                        </div>
                  
                    </div>
                    ) : null}


                    </Grid>
                    </Grid>


                   <Modal  style={{width:400, display:'flex',alignItems:'center',justifyContent:'center', margin:'0 auto', outline:'none'}}
                    aria-labelledby="modal"
                    aria-describedby="card"
                    className={theme.modal}
                    open={this.state.cardWaiting}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    maxWidth={'xs'}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.cardWaiting}>
                    <div className={theme.paper}>
                        <div style={{width:200,height:70,textAlign:'center',backgroundColor:'#fff',alignItems:'center',justifyContent:'center'}}>
                            <div style={{backgroundColor:'#fff',paddingTop:20,alignItems:'center',justifyContent:'center'}}>
                            <CircularProgress size={20} style={{marginRight:5}} color="inherit" />
                            Validando recarga...
                            </div>
                        </div>
                    </div>
                    </Fade>
                    </Modal>


                    </div>
                }     
            </div>  
        )
    }

}


const customStyles = {
    option: (provided, state) => ({
      ...provided,
        fontSize : 16
    }),
   
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }


const styles = theme => ({
    root: {
        flexGrow: 1,
        padddinTop : 30
      },
      cardT :{
        width:'100%'
      },
      cuadroC :{
        marginTop : 5,
        marginBottom : 5,
        marginLeft: 0,
        marginRight : 0,
        width : '100%',
        float : 'left'
        
    },
    cuadroTarjeta :{
        height : 'auto',
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 10,
        //minHeight:120,
        width : '100%',
        float : 'left',
        alignItems : 'center',
        alignSelf : 'center',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
        
    },
    modal:{
        "&:focus":{
        outline: 'none'
       }
     },
    cuadroT : {
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 20,
        marginBottom : 30,
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
    },
    removeCard :{
        width: 30,
        height: 30,
        fontSize : 16,
        lineHeight: '26px',
        textAlign: 'center',
        borderRadius : 20,
        color : '#fff',
        backgroundColor : '#eb6324',
        cursor : 'pointer',
        float : 'left',
        marginTop : '10px',
        marginRight : '10px'
    },
    cuadroSel : {
        backgroundColor: '#333',
        color : "#fff !important"

    },
    notSel : {
        display : 'none'
    },
    cuadroTitle:{
        fontSize: 16,     
        textAlign: 'center',
        marginBottom : 10,
        textTransform : 'uppercase',
        float : 'left',
        marginTop : '5px'
    },
    cuadroNombre : {
        textAlign : 'left',
        marginLeft : 20,
        float:' left',
        marginTop : 4,
        textTransform : 'uppercase',
    },
    cuadroDesc : {
        fontSize: 14,
        color : '#333',
        textAlign: 'center'
    },
    cuadroPrecio:{
        textAlign : 'center',
        color : '#eb6324',
        fontSize : 30,
        fontWeight : 'bold',
       
    },
    cuadroSub:{
        textAlign : 'center',
        color : '#eb6324',
        fontSize : 12,
        width: '100%',
        borderBottom : '1px solid #d8d8d8',
        paddingBottom : 10,
        marginBottom : 10,
        fontWeight : 'bold',
       
    },
    cuadroNumeroTarjeta:{
        textAlign : 'left',
        float: 'left',
        paddingLeft : 20,
        marginLeft : 20,
        color : '#eb6324',
        fontSize : 22,
        fontWeight : 'bold',
        marginTop : 10
       
    },
    cuadroPeriodo:{
        textAlign : 'center',
        color : '#eb6324',
        fontSize : 13,
        fontWeight : 'bold'
    },
    cuadroCostoSIM:{
        textAlign : 'center',
        fontSize : '14',
        fontWeight : 'bold',
        marginTop : 20,
        marginBottom : 10,
        paddingTop : 20,
        borderTop : '1px solid #ccc'
    },
    cuadroCar:{
        fontSize: 15,
        marginBottom : 10
        //marginLeft : 10
    },
    cuadroBt:{
        marginTop : 20,
        textAlign : 'center',
        padding : 5,
        backgroundColor : '#eb6324',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 20,
        fontWeight : 'bold',
        
        verticalAlign:'middle'
    },
    pagarBt : {
        marginTop : 0,
        textAlign : 'center',
        padding : 10,
        backgroundColor : '#eb6324',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 0,
        fontSize : 22,
        fontWeight : 'bold',
        cursor : 'pointer',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          },
        verticalAlign:'top'
    },
    title:{
        color : '#eb6324',
        fontSize : 24,
        
        marginTop : 40
    },
    comboTel : {
        fontSize:24,
        borderRadius : 5,
        padding : 10,
        width : 300
    },
    opps : {
        width: '496px',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '0 45px',
        margin: '0px auto',
        overflow: 'hidden',
        border: '1px solid #b0afb5',
        color: '#4f5365'
    },
    oppsReminder : {
        position: 'relative',
        top: '-1px',
        padding: '9px 0 10px',
        fontSize: '11px',
        textTransform: 'uppercase',
        textAlign: 'center',
        color: '#ffffff',
        background: '#000000'
    },
    oppsInfo : {
        marginTop: '26px',
        position: 'relative'
    },
    oppsInfoAfter :{
         visibility: 'hidden',
         display: 'block',
         fontSize: 0,
         content: " ",
         clear: 'both',
         height: 0
    
    },
    oppsBrand : {
        width: '45%',
        float: 'left'
    },
    oppsBrandImg : {
        maxWidth: '150px',
        marginTop: '2px'
    },  
    oppsAmount  :{
        width: '55%',
        float: 'right',  
    },
    oppsAmounth2: {
        fontSize: '36px',
        color: '#000000',
        lineHeight: '24px',
        marginBottom: '15px'
    },
    oppsAmounth3 :{
        marginBottom: '10px',
        fontSize: '15px',
        fontWeight: '600',
        textTransform: 'uppercase'
    },
    oppsAmountsup : {
        fontSize: '16px',
        position: 'relative',
        top: '-2px'
    },
    oppsAmountp: {
        fontSize: '10px',
        lineHeight: '14px'
    },
  
    oppsReference : {
        marginTop: '14px',
        
    },
    oppsReferenceh1 :{

        fontSize: '27px',
        color: '#000000',
        textAlign: 'center',
        marginTop: '-1px',
        padding: '6px 0 7px',
        border: '1px solid #b0afb5',
        borderRadius: '4px',
        background: '#f8f9fa'
    },
    oppsInstructions : {
        margin: '32px -45px 0',
        padding: '32px 45px 45px',
        borderTop: '1px solid #b0afb5',
        background: '#f8f9fa'
    },
    oppsFootnote : {
        marginTop: '22px',
        padding: '22px 20 24px',
        color: '#108f30',
        textAlign: 'center',
        border: '1px solid #108f30',
        borderRadius: '4px',
        background: '#ffffff'
    }
}) 
export default withStyles(styles)(Recargas);