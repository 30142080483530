import { url_server } from '../config.js'

import { setToken, delToken } from '../functions.js'

import React, { Component } from "react";
import {
    Container,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress
} from '@material-ui/core/';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import AppLayout from '../components/AppLayout';
import TextField from '../components/fields/TextField';
import CheckBoxField from '../components/fields/CheckBoxField';
import ButtonField from '../components/fields/ButtonField';
import AlertMessage from '../components/fields/AlertMessage';




const styles = theme => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginNormal : {
            marginTop: 0,
            marginBottom: 0
        },
        textAlign : 'center'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
})

class Login extends Component {

    state = {
        username : "",
        password : "",
        remember : true,
        error : false,
        message : undefined,
        waiting : false
    }

    setUsername = (event) => {
        this.setState({username : event.target.value})
    }

    setPassword = (event) => {
        this.setState({password : event.target.value})
    }

    setRemember = (event, value) => {
        this.setState({remember : value})
    }

    closeError = (event) => {
        this.setState({error : false})
    }

    login = async (e) => {
        this.setState({waiting:true});
        e.preventDefault();
        //console.log('DO LOGIN: ' + global.server + ':' + global.port + '/token')
        try {
            const response = await fetch(url_server + '/users/login', {
                //crossDomain : true,
                method  : 'GET', 
                headers : new Headers({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin' : '*',
                    //'Access-Control-Allow-Methods' : 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                    'Username' : this.state.username,
                    'Password' : this.state.password,
                    'Remember' : this.state.remember,
                }),
                //mode    : 'cors',
                //body: 'A=1&B=2'
            })

            console.log(response.status,"status login")

            switch(response.status){
                case 200 : {
                    const data = await response.json()

                    if(data.token){
                        setToken(data.token)
                        window.location = '/dash';
                        //this.props.history.push('/dash')
                    }else{
                        //this.props.history.push('/login')
                        this.setState({wating:false,error : true,message : 'Usuario o contraseña incorrectos.', severity:"error", msg:true})
                    }
                    break;
                }
                case 401 : {
                    delToken()
                    this.setState({wating:false,error : true,message : 'Usuario o contraseña incorrectos.', severity:"error", msg:true})
                    break;
                }
                default : {
                    delToken()
                    this.setState({wating:false,error : true, message : 'Error al iniciar sesion.', severity:"error", msg:true})
                    break;
                }
            }

        } catch(error) {
            console.log(error)

            this.setState({error : true})
            this.setState({message : 'Error al iniciar sesion.'})
        }
    }

    render(){
        const { classes } = this.props;

        return (
        
            <div >
        

            <Grid container spacing={1} direction="row" alignItems="flex-start" justify="center"   >


              <Grid item xs={12}  >
                
                <Card className={classes.card}>
                  <CardContent>
                    <Typography component="h1" variant="h5">
                      ingresar a mi cuenta FTE
                    </Typography>
                    <form className={classes.form} onSubmit={this.login} noValidate>
                      <TextField     value={this.state.username} label='Usuario'                 onChange={this.setUsername} />
                      <TextField     value={this.state.password} label='Contraseña'              onChange={this.setPassword} type='password' />
                      <CheckBoxField value={this.state.remember} label='Mantener sesion abierta' onChange={this.setRemember} />
                      
                      <Link onClick={()=>{this.props.recoveryModal()}}  variant="body2">
                            {"¿Tienes problemas para ingresar? Recuperación de contraseña"}
                      </Link>

                      {!this.waiting ? (
                          <div>
                           
                          <ButtonField  style={{float:'right'}} label='Inicia sesion' onSubmit={this.login} />
                          </div>
                      ): 
                      (
                        
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress color="inherit" size={22} />
                        </div>
                      )
                      }
                      
                      <Grid container>
                        {/* <Grid item xs>
                          <Link href="#" variant="body2">
                            ¿Olvidaste tu datos de acceso?
                          </Link>
                        </Grid>  */}
                        <Grid item>
                          <Link href="/cuentac" variant="body2">
                            {"¿No tienes cuenta? Date de alta"}
                          </Link>

                         
                        </Grid>
                      </Grid>
                      
                    </form>
                  </CardContent>
                </Card>
            </Grid>
            </Grid>
              
              {this.state.error ? <AlertMessage open={this.state.error} message={this.state.message} onClose={this.closeError} severity={this.state.severity} /> : null}
            
              </div>
        );
    }
}

export default withStyles(styles)(Login);