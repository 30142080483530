import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo } from '../components/dataController';

import { Grid, Backdrop, CircularProgress, Button, Modal, Container } from "@material-ui/core";
import theme from '../theme.js';
import Section from '../components/Section';

import Telefonia from '../contexts/Webpages/Telefonia';
import Telefoniafija from '../contexts/Webpages/Telefoniafija';
import Internet from '../contexts/Webpages/Internet';
import Cobertura from '../contexts/Webpages/Cobertura';
import Contacto from '../contexts/Webpages/Contacto';
import Recargasp from '../contexts/Webpages/RecargasP';
import Distribuidor from '../contexts/Webpages/Distribuidor';
import Recuperacion from '../contexts/Webpages/Recuperacion';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';

import WebLayout from '../components/WebLayout';
import HomeContent from '../components/HomeContent'
import { getAvisos } from '../components/dataController';
import tawkTo from "tawkto-react";

import AfiliaForm from '../pages/AfiliaForm';
import AlertMessage from '../components/fields/AlertMessage';
import ReactWhatsapp from 'react-whatsapp';




const tawkToPropertyId = '602aaa83918aa261273f1803'
const tawkToKey = '1eujti341'


const title = 'Inicio'

const styles = theme => ({
    backgroundColor : '#000',
    float :{
        position:'fixed',
        width:60,
        height:60,
        bottom:40,
        right:40,
        backgroundColor:'#25d366',
        color:'#FFF',
        borderRadius:50,
        textAlign:'center',
        fontSize:30,
        boxShadow: '2px 2px 3px #999',
        zIndex:100,
    },
    myFloat : {
        marginTop:16,
        width :30,
        height : 30
    }
})


class Home extends React.Component {

    state ={
        afiliaForm : false,
        msg : false,
        message : 'FTE ',
        severity : 'error'
    } 

    static contextType = UserContext;
    
    componentDidMount(){
        this.context.login(()=>{
            console.log("completed login")
        }); 
       // tawkTo(tawkToPropertyId, tawkToKey)
        this.getMessages();
    }

    getMessages = () =>{
        getAvisos().then(res =>{
            if(res.length>0){
                this.setState({msg:true,severity:res[0].tipo,message:res[0].mensaje})
            }
            
        })
    }

    gologin = () =>{
        this.props.history.push('/login')
    }

    gocrear = () =>{
        this.props.history.push('/cuentac')
    }

    openAfilia =  () =>{
        this.setState({afiliaForm:true});
    }

    closeAfilia =  () =>{
        this.setState({afiliaForm:false});
    }

    handleCloseModalAfilia = () =>{
        this.setState({afiliaForm:false});
    }

    onClose = (event) => {
        this.setState({msg : false})
    }

    render(){

        const { classes, history, session } = this.props;
        const data = this.state.data
        const { hash } = this.props.location


        return(

            <div >

            <ReactWhatsapp style={{position:'fixed',
            cursor:'pointer',
            border:'1px solid #fff',
            bottom:20,right:20,width:'70px',height:'70px',textAlign:'center',backgroundColor:'#25d366',borderRadius:'50%',boxShadow:'0px 4px 10px 0px rgb(0 0 0 / 15%)'}} number="+524428944194" message="Hola me gustaría información adicional." >
                    <svg style={{fill:'#fff',padding:',5rem',width:'50px',height:'50px'}} focusable="false" viewBox="0 0 24 24" width="55" height="55"><path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path></svg>
            </ReactWhatsapp>

            <Container style={{paddingBottom:0,paddingRight:20, paddingLeft:20}} maxWidth="lg" >
         
         {/* <AlertMessage open={this.state.msg} message={this.state.message} onClose={this.closeError} severity={this.state.severity} /> */}

            {this.state.msg ? (
                  <Alert variant="filled" severity={this.state.severity} onClose={this.state.onClose} >
                  {this.state.message}
              </Alert>    
      
            ) :null}
          


            </Container>

            <WebLayout  tab={hash != '' ? hash : '#inicio'} >
                
               
                
               {/* <a href="https://api.whatsapp.com/send?phone=5215568269633&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n" style={{ position:'fixed',
                        width:60,
                        height:60,
                        bottom:40,
                        right:40,
                        zIndex : 10000,
                        color:'#FFF',
                        borderRadius:50,
                        textAlign:'center',
                        position : "fixed"
                                                }} target="_blank">
                    <img src="whats.png" style={{width:60,height:60}} /> 
                </a>

                                            */}

                <Modal  style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                    aria-labelledby="afiliar"
                    aria-describedby="afiliacion"
                    className={theme.modal}
                    open={this.state.afiliaForm}
                    onClose={this.handleCloseModalAfilia}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    
                    }}
                >
                    <Fade in={this.state.afiliaForm}>
                    <div className={theme.paper}>
                       <AfiliaForm  closeAfilia ={this.closeAfilia}/>
                    </div>
                    </Fade>
                </Modal>






            { hash == '#inicio' || hash == '' ?
                <div id='#inicio'>
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <HomeContent afif={this.openAfilia} closeAfilia={this.closeAfilia}/>
                            </Grid> 
                        </Grid>
                    </div>
                    
                </div>
            : null }
            { hash == '#telefonia' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Telefonia afif={this.openAfilia} closeAfilia={this.closeAfilia} />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#telefoniafija' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Telefoniafija afif={this.openAfilia} closeAfilia={this.closeAfilia} />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
             { hash == '#internet' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Internet />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
           
            { hash == '#cobertura' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Cobertura/>
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#contacto' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Contacto />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#recargasp' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Recargasp />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
             { hash == '#distribuidor' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Distribuidor />
                            </Grid> 
                        </Grid>
                    </div>
            : null }
            { hash == '#recuperacion' ?
                    <div title='' >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Recuperacion />
                            </Grid> 
                        </Grid>
                    </div>
            : null }

            
        </WebLayout>
        </div>
        )
         
     
    }
}



export default withStyles(styles)(Home);