import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core/';
import AssignmentIndIcon  from '@material-ui/icons/AssignmentInd';


import { withStyles } from '@material-ui/core/styles';
import { home_sections } from '../config.js';


const styles = theme => ({
    categoryHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft : 30,
        cursor: 'pointer',
        '&:hover' :{
          backgroundColor : '#eb6324',
          color : '#fff'

        }
    },
    mifte : {
      marginTop : 5,
        cursor :'pointer',
        paddingTop: theme.spacing(1),
        paddingLeft : 30,
        fontSize : 18,
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
       
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
       
    },
    navlink : {
        color : theme.palette.common.white,
        textDecoration: 'none',
    },
    drawer : {
      root : {
        backgroundColor : '#fff'
      }
    },
    drawerOpen: {
        width: 350,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      }
});

class WebNavigator extends Component {

 
    navigate = (link) => {
        const { history } = this.props;
       
        this.toggleDrawer(false)
        if(history.location.pathname != link){
            history.push(link);
        }
    }

    componentWillMount() {
        //const { history } = this.props;

        //console.log(history)
    }


     toggleDrawer = (event) => {
       console.log(event)
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.props.retf(false)
    };

    handleClose = () =>{
      this.props.retf(false)
  }
   

    

    render(){
        const { classes, history, ...other } = this.props;

        return (
            <div>
              <Drawer className={classes.drawer} onClose={this.handleClose} open={this.props.open} onKeyDown={()=>{this.toggleDrawer(false)}} >
              <List disablePadding >
              <img src="logofte.svg" style={{height:44, marginTop:30, marginLeft:30 }}/>
                <div style={{marginTop:30}}>
                {home_sections.map(({ id, name, url }) => (
                  <React.Fragment key={id}>
                    <ListItem className={classes.categoryHeader} onClick={() =>{this.navigate(url)}}>
                      <ListItemText
                        classes={{
                          primary: '#333',
                          
                        }}
                      >
                        {name}
                      </ListItemText>
                    </ListItem>
                 
                  </React.Fragment>
                ))}
                </div>
                <ListItem className={classes.mifte} onClick={this.props.mifte}>
                      <div style={{ display: 'flex',alignItems: 'center',flexWrap: 'wrap', fontWeight:'bold'}}>Mi FTE <AssignmentIndIcon  style={{fill: "#eb6324", marginLeft:10 }}/></div>
                    </ListItem>
              </List>
              
              </Drawer>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(WebNavigator));